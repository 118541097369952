import 'moment/locale/fr'
import moment from 'moment'
import { Select } from 'antd'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { modalActions } from '_actions'
import Tabs from 'components/navigation/Tabs'
import Button from 'components/buttons/Button'
import CheckBox from 'components/filters/CheckBox'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'
import { messagesActions, askActions } from '_actions'
import useSwiperRef from 'components/utils/useSwiperRef'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { IconDate, IconLike, IconDiapo, IconComment, IconMention } from '_svg'
import askTranslations from 'components/pages/translations/AskFormTranslations.json'

SwiperCore.use([Navigation, Pagination])

export default function AskMessage({ ...modalProps }) {
  const { Option } = Select
  const messages = useSelector((state) => state.messages)

  const dispatch = useDispatch()

  const [activeTs, setActiveTs] = useState('fr')
  const [swiperSelection, setSwiperSelection] = useState([])
  const [swiperSelectionType, setSwiperSelectionType] = useState([])
  const [swiperSelectionURL, setSwiperSelectionURL] = useState([])
  const [paginationEl, paginationRef] = useSwiperRef()
  const [selectedMsg, setSelectedMsg] = useState()
  const [hash, setHash] = useState('')
  const [formURL, setFormURL] = useState('')
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [error, setError] = useState('')
  const [discountCode, setDiscountCode] = useState('')
  const [legalsUrl, setLegalsUrl] = useState('')

  const format = (message) => {
    if (!message) return

    let updatedString = message.replaceAll('[description]', modalProps.media.caption)
    updatedString = updatedString.replaceAll('[post_link]', modalProps.media.permalink)
    updatedString = updatedString.replaceAll('[nb_likes]', modalProps.media.likeCount)
    updatedString = updatedString.replaceAll('[nb_comments]', modalProps.media.commentsCount)
    updatedString = updatedString.replaceAll('[date]', moment(modalProps.media.timestamp).locale('fr').format('L'))

    return updatedString
  }

  useEffect(() => {
    if (messages.length <= 0) {
      dispatch(messagesActions.getAllMessages())
    } else {
      setSelectedMsg(messages[0])
      setHash(randomChain(11))
    }
  }, [dispatch, messages])

  useEffect(() => {
    // because sometimes hash is empty.... don't know why
    if (hash === '') setHash(randomChain(11))
    // then setup form URL
    setFormURL(`https://${window.location.host}/form/${hash}`)
  }, [hash])

  const selectMsg = (ts, index) => {
    setSelectedMsg(messages[index])
  }

  const randomChain = (nbDigits) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (var i = 0; i < nbDigits; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const getImageUrl = (data) => {
    try {
      window.atob(data.substr(23))
      return data
    } catch (e) {
      return `https://instagram.colegram.workers.dev/?feed_id=${modalProps.media.feedId}&image_url=${btoa(data)}`
    }
  }

  const getMedia = () => {
    if (modalProps.media.mediaType === 'VIDEO') {
      return (
        <div className="visual">
          <video controls autoPlay muted loop>
            <source
              src={`https://instagram.colegram.workers.dev/?feed_id=${modalProps.media.feedId}&video_url=${btoa(modalProps.media.mediaUrl)}`}
              type="video/mp4"
            />
          </video>
        </div>
      )
    } else if (modalProps.media.mediaType === 'IMAGE') {
      return <div className="visual" style={{ backgroundImage: `url('${getImageUrl(modalProps.media.mediaUrl)}')` }} />
    } else if (modalProps.media.mediaType === 'SIDECAR') {
      const children = JSON.parse(modalProps.media.children)
      const getSwiperMedia = () => {
        return children.map((el, i) => {
          if (el.media_type === 'VIDEO') {
            return (
              <SwiperSlide key={i}>
                <div className="visual">
                  <video controls>
                    <source
                      src={`https://instagram.colegram.workers.dev/?feed_id=${modalProps.media.feedId}&video_url=${btoa(el.media_url)}`}
                      type="video/mp4"
                    />
                  </video>
                  <CheckBox name={`${el.media_type}|${el.id}|${el.media_url}`} callback={selectCarouselItem} />
                </div>
              </SwiperSlide>
            )
          } else {
            return (
              <SwiperSlide key={i}>
                <div className="visual" style={{ backgroundImage: `url('${getImageUrl(el.media_url)}')` }}>
                  <CheckBox name={`${el.media_type}|${el.id}|${el.media_url}`} callback={selectCarouselItem} />
                </div>
              </SwiperSlide>
            )
          }
        })
      }
      return (
        <>
          <div className="selection-info">
            Vous avez selectionné{' '}
            <span className="text-gradient text-gradient--H">
              {swiperSelection.length} média{swiperSelection.length > 1 && 's'}
            </span>
          </div>
          <Swiper spaceBetween={0} slidesPerView={1} navigation pagination={{ el: paginationEl }} threshold={30}>
            {getSwiperMedia()}
            <IconDiapo className="icon-diapo" />
          </Swiper>
          <div ref={paginationRef} />
        </>
      )
    }
  }

  const selectCarouselItem = (el) => {
    const mediaSplit = el.name.split('|')
    if (el.checked) {
      setSwiperSelection([...swiperSelection, parseInt(mediaSplit[1])])
      setSwiperSelectionType([...swiperSelectionType, mediaSplit[0]])
      setSwiperSelectionURL([...swiperSelectionURL, mediaSplit[2]])
      if (error.length > 0) setError('')
    } else {
      var index = swiperSelection.indexOf(mediaSplit[1])
      if (index !== -1) {
        let temp = [...swiperSelection]
        temp.splice(index, 1)
        setSwiperSelection(parseInt(temp))
        temp = [...swiperSelectionType]
        temp.splice(index, 1)
        setSwiperSelectionType(temp)
        temp = [...swiperSelectionURL]
        temp.splice(index, 1)
        setSwiperSelectionURL(temp)
      }
    }
  }

  const handleAsk = () => {
    setIsSubmiting(true)
    setError('')
    if (modalProps.media.mediaType === 'SIDECAR') {
      if (swiperSelection.length === 0) {
        setError('Veuillez selectioner au moins un media dans le carousel')
        setIsSubmiting(false)
        return
      }
    }
    const m = modalProps.media
    const datas = { hash }
    datas.hashtags = m.hashtags
    datas.caption = m.caption
    datas.commentsCount = m.commentsCount
    datas.feed_id = m.feedId
    datas.assetId = m.mediaType === 'SIDECAR' ? swiperSelection : [parseInt(m.id)]
    datas.likeCount = m.likeCount
    datas.mediasType = m.mediaType === 'SIDECAR' ? swiperSelectionType : [m.mediaType]
    datas.mediasUrl = m.mediaType === 'SIDECAR' ? swiperSelectionURL : [m.mediaUrl]
    datas.permalink = m.permalink
    datas.timestamp = m.timestamp
    datas.authorName = m.author
    datas.authorUsername = m.authorUsername
    datas.lang = activeTs
    datas.message = selectedMsg.translations[activeTs]
    datas.createdAt = Date.now()
    datas.main_asset_id = m.id
    datas.customVars = {
      discountCode: discountCode,
      legalsUrl: legalsUrl
    }
    dispatch(askActions.create(datas, askCreated))
  }

  const askCreated = (success, error) => {
    setIsSubmiting(false)
    if (success) {
      let beforeLinkSentence = askTranslations[activeTs].askMessageBeforeLink

      const text = `${selectedMsg.translations[activeTs]}\n\n ${beforeLinkSentence} : \n${formURL}`
      navigator.clipboard.writeText(text).then(function () {
        window.open(modalProps.media.permalink, '_blank')
      })
    } else {
      setError(error.message)
    }
  }

  const handleComment = (success, error) => {
    setIsSubmiting(false)
    if (success) {
      navigator.clipboard.writeText(selectedMsg.commentTranslations[activeTs]).then(function () {
        window.open(modalProps.media.permalink, '_blank')
      })
    } else {
      setError(error.message)
    }
  }

  const goToMessageCreation = () => {
    dispatch(modalActions.closeModal())
  }

  return (
    <div className="modal-ask-message">
      <div className="col-left">
        {getMedia()}
        <div className="media-stats" style={{ marginTop: 20 }}>
          <div className="media-likes">
            <IconMention className="icon" style={{ marginRight: 0 }} />
            <span className="media-stats-label" style={{ fontWeight: 'bolder' }}>
              {modalProps.media.authorUsername}
            </span>
          </div>
        </div>
        <SimpleBar autoHide={false} style={{ maxHeight: 150, marginTop: 15 }}>
          {modalProps.media.caption}
        </SimpleBar>
        <div className="media-stats" style={{ marginTop: 20 }}>
          <div className="media-date">
            <IconDate className="icon" />
            <span className="media-stats-label">
              {moment(modalProps.media.timestamp * 1000)
                .locale('fr')
                .format('L')}
            </span>
          </div>
          <div className="media-likes">
            <IconLike className="icon" />
            <span className="media-stats-label">{modalProps.media.likeCount} likes</span>
          </div>
          <div className="media-comment">
            <IconComment className="icon icon--grey-light" />
            <span className="media-stats-label">{modalProps.media.commentsCount} commentaires</span>
          </div>
        </div>
      </div>
      <div className="modal-ask-message__content">
        <div>
          <div className="modal__title">Faire une demande de droits</div>
          {messages.length > 0 ? (
            <>
              <div className="modal__description" style={{ fontSize: "12px" }}>
                <br />
                Choisissez le message que vous souhaitez envoyer pour demander les droits sur cette publication. <br />
                <br />
                Veillez à ce que le post ne comporte pas d'éléments qui pourraient porter atteinte aux droits de tiers
                (marques verbales, logotypes, slogan, etc. déposés à titre de marques).
              </div>

              <div className="label label--med">Langue</div>
              <Select
                name="lang"
                value={activeTs}
                onChange={(value) => setActiveTs(value)}
                style={{ width: 120, color: '#fff', backgroundColor: '#000' }}
              >
                <Option value="fr">FR</Option>
                <Option value="en">EN</Option>
                <Option value="de">DE</Option>
                <Option value="it">IT</Option>
                <Option value="pt">PT</Option>
                <Option value="es">ES</Option>
              </Select>

              <Tabs
                format={format}
                contents={messages}
                selectFn={selectMsg}
                tabsLabel="Message"
                translation={activeTs}
              />
              {/* <input type="text" name="" id="" className="url-input" disabled defaultValue={formURL} /> */}
              <div className="label label--med">Code PROMO</div>
              <input
                type="text"
                className="input-text-grey"
                placeholder="Ajouter un code promotionnel"
                defaultValue={discountCode}
                onChange={e => {
                  setDiscountCode(e.target.value)
                }}
              />
              <div className="label label--med">Lien CGU</div>
              <input
                type="text"
                className="input-text-grey"
                placeholder="Ajouter un lien"
                defaultValue={legalsUrl}
                onChange={e => {
                  setLegalsUrl(e.target.value)
                }}
              />
            </>
          ) : (
            <div className="modal__description">
              Vous devez d'abord{' '}
              <Link to="/messages">
                <strong onClick={goToMessageCreation}>créer au moins un modèle de message</strong>
              </Link>{' '}
              pour pouvoir faire une demande de droits.
            </div>
          )}
        </div>

        <div>
          {messages.length > 0 && (
            <>
              <Button
                btnStyle="plain"
                onClickFn={handleAsk}
                loading={isSubmiting}
                disabled={isSubmiting}
                addedclass="w-full mt-6"
              >
                Copier le DM et aller sur {modalProps.media.source}
              </Button>
              {selectedMsg?.commentTranslations && selectedMsg?.commentTranslations[activeTs] &&
                <Button
                  btnStyle="plain"
                  onClickFn={handleComment}
                  loading={isSubmiting}
                  disabled={isSubmiting}
                  addedclass="w-full mt-6"
                >
                  Copier le commentaire et aller sur {modalProps.media.source}
                </Button>
              }
            </>
          )}
          {error.length > 0 && <div className="alert alert-danger w-full mt-3">{error}</div>}
        </div>
      </div>
    </div>
  )
}
