import { useEffect, useState, useRef } from "react"
import Infos from "./Infos"
import Modal from "./Modal"

const Wall = ({ isFull, templateInfos, wall }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalInfos, setModalInfos] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const wallRef = useRef(null)

  const openModal = (el) => {
    setModalOpen(true)
    setModalInfos(el)
  }

  useEffect(() => {
    if (wallRef.current) {
      const images = [...wallRef.current.querySelectorAll("div img")];
      const proms = images.map(im=>new Promise(res=>
        im.onload=()=>res([im.width,im.height])
      ))

      Promise.all(proms).then(() =>{
        setIsLoaded(true)
      })
    }
  }, [wallRef])

  return (
    <div className="relative">
      <div className={isFull ? 'text-center' : ''}>
        <h1 className="landing__title">{templateInfos.titleWall}</h1>
        { templateInfos.subtitle &&
          <p className="landing__intro" dangerouslySetInnerHTML={{__html: templateInfos.subtitle}}></p>
        }
      </div>

      <div ref={wallRef}>
        <ul className={`landing-wall ${!isLoaded ? 'landing-wall--hidden' : ''} landing-wall--${isFull ? 'full' : 'sidebar' }`}>
          { wall?.map((el, i) => {
            el.platform = el.permalink.includes('www.tiktok.com') ? 'tiktok' : 'insta'

            return (
              <li key={i} className="landing-wall__item">
                <button onClick={() => openModal(el)}>
                  { el.type === 'VIDEO'
                    ? <video loading="lazy" className="w-full">
                        <source src={el.path} />
                      </video>
                    : <img loading="lazy" className="w-full" src={el.path} alt="" />
                  }

                  <div className="landing-wall__hover">
                    <Infos
                      platform={el.platform}
                      likes={el.likeCount}
                      comments={el.commentsCount}
                      date={el.timestamp}
                      user={el.authorUsername ?? el.authorName}
                    />
                  </div>
                </button>
              </li>
            )
          }) }
        </ul>
        
        { !isLoaded &&
          <p className="landing-wall__loader"></p>
        }
      </div>


      { modalOpen &&
        <Modal modalInfos={modalInfos} setModalOpen={setModalOpen} modalOpen={modalOpen} />
      }
    </div>
  )
}

export default Wall