import { IconDelete } from "_svg"
import { ReactComponent as IconChevronDown } from "../../_svg/icon-chevron-down.svg"
import { useState } from "react"

export default function SidebarItem({ item, i, setLanding, landing, open }) {
  const [currentItem, setCurrentItem] = useState(item)
  const [isOpen, setIsOpen] = useState(open)

  const removeSidebarItem = () => {
    const oldItems = landing.sidebarItems
    const tempLanding = {}
    tempLanding.sidebarItems = []
    setLanding({ ...landing, ...tempLanding })

    setTimeout(() => {
      const tempLanding2 = {}
      tempLanding2.sidebarItems = oldItems.filter((newItem, key) => key !== i )
      setLanding({ ...landing, ...tempLanding2 })
    }, 0)
  }

  const updateField = (value, field) => {
    const temp = {}
    temp[field] = value
    setCurrentItem({ ...currentItem, ...temp })

    const tempItems = landing.sidebarItems
    tempItems[i] = { ...currentItem, ...temp }
    const tempLanding = {}
    tempLanding.sidebarItems = tempItems
    setLanding({ ...landing, ...tempLanding })
  }

  const getBase64 = (e) => {
    const temp = {}
    if (e.currentTarget.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(e.currentTarget.files[0])

      reader.onload = () => {
        temp.pic = reader.result
        updateField(reader.result, 'pic')
      }

      reader.onerror = (error) => {
        console.log('Error: ', error)
        return error
      }
    } else {
      updateField('pic', null)
    }
  }

  return (
    <div className="collection-item">
      <button onClick={() => setIsOpen(!isOpen)} type="button" className={`collection-item__title label ${isOpen ? 'is-open' : ''}`}>
        Elément {i + 1}

        <IconChevronDown />
      </button>

      <div className={`collection-item__content-wrap ${isOpen ? 'is-open' : ''}`}>
        <div className="collection-item__content">
          <div className="collection-item__inner">
            <label htmlFor="elTitle" className="label">
              Titre
            </label>
            <input
              type="text"
              name="elTitle"
              id="elTitle"
              defaultValue={item.title}
              className="text-input"
              onChange={(e) => updateField(e.currentTarget.value, 'title')}
            />

            <label htmlFor="elStars" className="label">
              Nombre d'étoiles
            </label>
            <input
              type="number"
              name="elStars"
              id="elStars"
              className="text-input"
              min="1"
              max="5"
              defaultValue={item.stars}
              onChange={(e) => updateField(e.currentTarget.value, 'stars')}
            />

            <label htmlFor="elDesc" className="label">
              Description
            </label>
            <input
              type="text"
              name="elDesc"
              id="elDesc"
              className="text-input"
              defaultValue={item.desc}
              onChange={(e) => updateField(e.currentTarget.value, 'desc')}
            />

            <label htmlFor="elTag" className="label">
              Tag
            </label>
            <input
              type="text"
              name="elTag"
              id="elTag"
              className="text-input"
              defaultValue={item.tag}
              onChange={(e) => updateField(e.currentTarget.value, 'tag')}
            />

            <label htmlFor="elLink" className="label">
              Lien
            </label>
            <input
              type="text"
              name="elLink"
              id="elLink"
              className="text-input"
              defaultValue={item.link}
              onChange={(e) => updateField(e.currentTarget.value, 'link')}
            />

            <label htmlFor="pic" className="label">
              Image
            </label>
            <input
              type="file"
              name="pic"
              id="pic"
              className="file-input"
              onChange={getBase64}
            />

            <button type="button" className="button-more mt-6" onClick={() => removeSidebarItem() }><span><IconDelete className="icon" /></span>Supprimer cet item</button>
          </div>
        </div>
      </div>
    </div>
  )
}
