import ProductSidebar from "./ProductSidebar"
import { useEffect, useRef, useState } from "react"
import { useEventListener } from "react-recipes"

const Sidebar = ({templateInfos}) => {
  const sidebar = useRef(null)
  const sidebarInner = useRef(null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [sidebarInnerHeight, setSidebarInnerHeight] = useState(0)
  const [offsetTop, setOffsetTop] = useState(0)
  const [sidebarWidth, setSidebarWidth] = useState(0)

  const handleResize = () => {
   setViewportHeight(window.innerHeight)
   setSidebarInnerHeight(sidebarInner.current.offsetHeight)
   setOffsetTop(sidebar.current.offsetTop)
   setSidebarWidth(sidebar.current.offsetWidth)
  }

  const handleScroll = () => {
    if (sidebar.current && sidebarInner.current && window.innerWidth > 1024) {
      const scrollTop = window.scrollY

      if (sidebarInnerHeight > viewportHeight) {
        if (scrollTop + viewportHeight - offsetTop >= sidebarInnerHeight) {
          sidebarInner.current.classList.add('is-bottomed')
          sidebarInner.current.style.width = `${sidebarWidth}px`
        } else {
          sidebarInner.current.classList.remove('is-bottomed')
          sidebarInner.current.style.width = ''
        }
      } else {
        if (scrollTop >= offsetTop - 16) {
          sidebarInner.current.classList.add('is-fixed')
          sidebarInner.current.style.width = `${sidebarWidth}px`
        } else {
          sidebarInner.current.classList.remove('is-fixed')
          sidebarInner.current.style.width = ''
        }
      }
    }
  }

  useEffect(() => {
    if (sidebar.current && sidebarInner.current) {
      handleResize()
    }
  }, [sidebarInner, sidebar])

  useEventListener("scroll", handleScroll)
  useEventListener("resize", handleResize)

  return (
    <aside className="landing-sidebar" ref={sidebar}>
      <div className="landing-sidebar__inner" ref={sidebarInner}>
        { templateInfos.sidebarTitl &&
          <h2 className="landing-sidebar__title">{templateInfos.sidebarTitle}</h2>
        }

        <ul>
          { templateInfos.sidebarItems.map((el, i) => {
            return (
              <li className="landing-sidebar__item" key={i}>
                <ProductSidebar product={el} />
              </li>
            )
          }) }
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar