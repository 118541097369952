import Wall from "components/frontLanding/Wall"
import Sidebar from "components/frontLanding/Sidebar"
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { landingsService } from "_services"
import { Helmet } from 'react-helmet'
import { useEventListener } from "react-recipes"

function FrontLanding () {
  const urlParams = useParams()
  const [isFull, setIsFull] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [templateInfos, setTemplateInfos] = useState(null)
  const [wall, setWall] = useState(null)
  const [firstWall, setFirstWall] = useState(null)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0)

  const breakpoints = {
    small: 768,
    medium: 1024,
    large: 1280
  }

  const reorderArray = (array, cols) => {
    const group1 = []
    const group2 = []
    const group3 = []

    for (let i = 0; i < array.length; i++) {
      if (i % cols === 0) {
        group1.push(array[i])
      } else if (i % cols === 1) {
        group2.push(array[i])
      } else {
        group3.push(array[i])
      }
    }

    return group1.concat(group2, group3)
  }

  const reorderWall = (array, fullLayout) => {
    const screenWidth = window.innerWidth
    let cols = 1

    if (screenWidth >= 768) {
      if ((screenWidth >= 1024 && fullLayout) || (screenWidth >= 1280 && !fullLayout)) {
        cols = 3
      } else {
        cols = 2
      }
    }
    
    const orderedArray = reorderArray(array, cols)
    setWall(orderedArray)
  }

  const handleResize = () => {
    const windowWidth = window.innerWidth;

    // Déterminer le nouveau breakpoint
    let newBreakpoint = ''

    if (windowWidth < breakpoints.small) {
      newBreakpoint = 'small'
    } else if (windowWidth < breakpoints.medium) {
      newBreakpoint = 'medium'
    } else if (windowWidth < breakpoints.large) {
      newBreakpoint = 'large'
    } else {
      newBreakpoint = 'xlarge'
    }

    if (newBreakpoint !== currentBreakpoint) {
      setCurrentBreakpoint(newBreakpoint)
      reorderWall(firstWall, isFull)
    }
  }

  useEffect(() => {
    const domain = window.location.hostname

    if (['homair.colegram.io', 'marvilla.colegram.io', 'app-dev.colegram.io', 'localhost'].includes(domain)) {
      landingsService.getSingleBySlug(urlParams.slug).then((result) => {
        document.body.style.setProperty('--landing-accent-color', result.template.titleColor)
        document.body.style.setProperty('--landing-button-bg-color', result.template.ctaColor)
        document.body.style.setProperty('--landing-button-radius', `${result.template.ctaRadius}px`)

        if(domain !== 'localhost' && domain !== 'app-dev.colegram.io' && result.template.domain !== domain) {
          window.location = `https://${result.template.domain}/landing/${urlParams.slug}`
        }
  
        setIsFull(result.template.type === 'full')
        setTemplateInfos({
          title: result.landing.title,
          titleWall: result.landing.titleWall,
          banner: result.landing.banner,
          sidebarTitle: result.landing.sidebarTitle,
          sidebarTitle: result.landing.sidebarTitle,
          sidebarItems: result.landing.sidebarItems,
          logo: result.template.logo,
          subtitle: result.landing.subtitle,
          ctaWording: result.template.ctaWording,
          ctaBisWording: result.template.ctaBisWording,
          ctaLink: result.landing.ctaLink
        })
        
        setFirstWall(result.assets)
        reorderWall(result.assets, result.template.type === 'full')
        setIsLoaded(true)
      }).catch(error => {
        console.log("ERROR", error)
        //window.location = 'https://colegram.io'
      })
    } else {
      window.location = `https://homair.colegram.io/${urlParams.slug}`
    }
  }, [])

  useEventListener("resize", handleResize)

  return (
    <div className="landing">
      { isLoaded &&
        <>
          <Helmet>
            <title>{templateInfos.title}</title>
          </Helmet>
          <div className="landing__inner">
            {/* banner */}
            { templateInfos.banner &&
              <div className="landing__banner">
                <h1>{templateInfos.title}</h1>
                <img src={templateInfos?.banner} alt="" />
              </div>
            }

            {/* logo + cta  */}
            <header className="landing__header">
              <img className="landing__logo" src={templateInfos?.logo} alt="Homair" />

              <a href={templateInfos.ctaLink} target="_blank" className="landing-button">Accéder au site</a>
            </header>

            <main>
              {isFull
                ? <Wall isFull={isFull} templateInfos={templateInfos} wall={wall} />
                : <div className="landing__sidebar-container">
                    <Wall isFull={isFull} templateInfos={templateInfos} wall={wall} />
                    <Sidebar templateInfos={templateInfos} />
                  </div>
              }
              
            </main>
          </div>
        </>
      }
    </div>
  )
}

export default FrontLanding